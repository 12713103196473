/* GENERAL */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  background: #1a1a2e;
  color: #ffffff;
}

html {
  scroll-behavior: smooth;
}

p {
  color: #e0e0e0;
}

/* TRANSITION */
a,
.btn {
  transition: all 300ms ease;
}

/* DESKTOP NAV */
#desktop-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  background: rgba(26, 26, 46, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  transition: all 0.3s ease;
}

.nav-links {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  gap: 2.5rem;
  list-style: none;
  font-size: 1.5rem;
}

a {
  color: #ffffff;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 0;
}

a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #00fff5;
  transition: width 0.3s ease;
}

a:hover::after,
a.active::after {
  width: 100%;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  color: #00fff5;
  text-shadow: 0 0 10px rgba(0, 255, 245, 0.3);
}

.logo:hover {
  cursor: default;
}

/* HAMBURGER MENU */
#hamburger-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 46, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: #00fff5;
  transition: all 0.3s ease-in-out;
}

.menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(26, 26, 46, 0.95);
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.menu-links a {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  text-decoration: none;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 300px;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

/* SECTIONS */
section {
  padding-top: 4vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
  position: relative;
}

.section-container {
  display: flex;
  gap: 4rem;
  height: 80%;
}

/* PROFILE SECTION */
#profile {
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 100vh;
  padding-top: 6rem;
}

.section__pic-container {
  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
  border-radius: 50%;
  box-shadow: auto 0 10px rgba(0, 255, 245, 0.2);
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
}

.section__text__p1 {
  text-align: center;
  font-size: 1.5rem;
  color: #00fff5;
  
  padding-top: 4rem;
}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(0, 255, 245, 0.3);
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

/* ICONS */
.icon {
  cursor: pointer;
  height: 2rem;
  filter: invert(89%) sepia(61%) saturate(454%) hue-rotate(140deg) brightness(102%) contrast(101%);
  transition: all 0.3s ease;
}

.icon:hover {
  transform: translateY(-5px);
}

/* BUTTONS */
.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
  border: 2px solid #00fff5;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
}

.btn:hover {
  background: #00fff5;
  color: #1a1a2e;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 255, 245, 0.2);
}

/* ABOUT SECTION */
#about {
  position: relative;
}

.about-containers {
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container {
  display: flex;
}

.about-pic {
  border-radius: 2rem;
}

.arrow {
  position: absolute;
  right: -5rem;
  bottom: 2.5rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.details-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.section-container {
  gap: 4rem;
  height: 80%;
}

/* EXPERIENCE SECTION */
#experience {
  position: relative;
}

.experience-sub-title {
  color: #00fff5;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

.experience-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.article-container {
  display: flex;
  text-align: initial;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2.5rem;
  justify-content: space-around;
}

article {
  display: flex;
  width: 10rem;
  gap: 0.5rem;
  align-items: center;
}

article .icon {
  cursor: default;
}

.skill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.skill-percentage {
  color: #00fff5;
  font-weight: 600;
}

.skill-item {
  width: 100%;
  margin-bottom: 1rem;
}

.skill-info {
  width: 100%;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-top: 0.5rem;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 1s ease;
}

/* PROJECTS SECTION */
#projects {
  position: relative;
}

.color-container {
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.project-img {
  border-radius: 2rem;
  width: 90%;
  height: 90%;
}

.project-title {
  margin: 1rem;
  color: #00fff5;
}

/* CONTACT */
#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  margin: 2rem auto;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
  transition: all 0.3s ease;
}

.contact-info-container:hover {
  transform: translateY(-3px);
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

/* FOOTER SECTION */
footer {
  height: 26vh;
  margin: 0 1rem;
}

footer p {
  text-align: center;
  color: #00fff5;
}
