.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95.7vh;
  background: #1a1a2e;
  padding: 1rem;
  position: relative;
  overflow: auto;
}

.game-title {
  font-size: 2.8rem;
  color: #00fff5;
  margin-bottom: 0.5rem;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(0, 255, 245, 0.3);
}

.player-turn {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 2rem;
  padding: 0.5rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.game-board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background: #16213e;
  padding: 15px;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cell {
  border: none;
  background: #1a1a2e;
  border-radius: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cell:hover:not(:disabled) {
  background: #242444;
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(0, 255, 245, 0.2);
}

.cell.filled {
  background: #242444;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cell.O {
  color: #00fff5;
  text-shadow: 0 0 10px rgba(0, 255, 245, 0.5);
}

.cell.X {
  color: #ff2e63;
  text-shadow: 0 0 10px rgba(255, 46, 99, 0.5);
}

.winner-message {
  background: #16213e;
  padding: 1.5rem 2rem;
  border-radius: 24px;
  margin: 1rem 0;
  text-align: center;
  font-size: 2rem;
  color: #00fff5;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.new-game-btn, .reset-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.8rem 1.8rem;
  margin: 1rem;
  border: none;
  border-radius: 50px;
  background: #ff2e63;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.new-game-btn:hover, .reset-btn:hover {
  background: #ff4778;
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(255, 46, 99, 0.4);
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 50px;
  background: #16213e;
  color: #00fff5;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.back-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(0, 255, 245, 0.2);
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .game-title {
    font-size: 2.5rem;
  }

  .winner-message {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
  }

  .back-btn {
    position: static;
    margin-bottom: 20px;
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .game-container {
    padding: 1rem;
  }
}

@media (max-width: 820px) {
  .back-btn {
    position: static;
    margin-bottom: 20px;
  }

  .game-container {
    padding-top: 1rem;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a2e;
}

::-webkit-scrollbar-thumb {
  background: #00fff5;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00ccc5;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00fff5 #1a1a2e;
}
